<template>

<app-wrapper class="share">

	<h4>Share</h4>

	<div class="share-buttons">

		<a :href="$state.page.share.linkedin" target="_blank" class="share-buttons-item"><i class="fa fa-linkedin"></i></a>
		<a :href="$state.page.share.twitter" target="_blank" class="share-buttons-item"><i class="fa-brands fa-bluesky"></i></a>
		<a :href="$state.page.share.email" target="_blank" class="share-buttons-item"><i class="fa fa-envelope"></i></a>

	</div>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.share {
	background-color: #EDEFFA;
	border-radius: 10px;
	padding: 20px 0px 40px 0px;
	margin-bottom: 40px;
}

.is-device .share {
	margin-bottom: 20px;
}

.share h4 {
	color: #1A2046;
	font-size: 14px;
	text-align: center;
	letter-spacing: 1.4px;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.share-buttons {
	display: flex;
	justify-content: center;
}

.share-buttons-item {
	width: 44px;
	height: 44px;
	background-color: #1A2046;
	color: #EDEFFA;
	border-radius: 50%;
	line-height: 48px;
	text-align: center;
	font-size: 20px;
	margin: 0px 5px;
}

.share-buttons-item:hover {
	color: #fff;
}

</style>
